body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mapTab{
  width:75%;
  height: 77%;
}

.rs__cell.rs__header.rs__time {
  writing-mode: vertical-rl;  
  transform: rotate(180deg);  
  text-align: center;         
  font-size: 12px;            
  font-weight: bold;          
  color: #333;               
  height: 60px;              
  display: flex;             
  align-items: center;       
  justify-content: center;   
}
.MuiChip-label.MuiChip-labelMedium {
  padding-right: 0px;
}
.css-wsy2rv{
  max-width: 100%;
  width: 352px;
}
.MuiPaper-root.MuiMenu-paper{
  max-height: 250px;
  overflow-y: auto;
}
/* .rs__cell.rs__header.rs__time {
  writing-mode: horizontal-tb; 
  transform: none; 
  color: #333;
  height: auto; 
  display: flex;
  font-size: 4px;
  min-width: 60px; 
  white-space: nowrap; 
  overflow: hidden; 
} */